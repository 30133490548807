import {
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  UseDisclosureProps,
} from "@chakra-ui/react";
import { Text } from "@/ui/Components/Text";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSupabase } from "@/hooks/useSupabase";
import { Controller, useForm, useWatch } from "react-hook-form";
import { UserProfile } from "@/lib/services/profile.supabase.service";
import { toast, ToastType } from "../toast";

type Props = UseDisclosureProps & {};

export const EditProfileModal: FC<Props> = ({ ...modalProps }) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>();
  const {
    user,
    authService: { profile: profileService },
    publicService,
    handleFetchProfile,
  } = useSupabase();

  const {
    formState: { errors },
    handleSubmit,
    ...form
  } = useForm<UserProfile>({
    defaultValues: { nickname: "", bio: "", avatar_url: "" },
  });

  const formValues = useWatch({ control: form.control });

  useEffect(() => {
    if (!user) return;
    if (user.avatar_url) form.setValue("avatar_url", user.avatar_url);
    if (user.nickname) form.setValue("nickname", user.nickname);
    if (user.bio) form.setValue("bio", user.bio);
  }, [user]);

  const handleFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];
      if (!file) return;
      if (!publicService) return;
      try {
        const url = await publicService.utility.uploadImage(file);
        form.setValue("avatar_url", url);
      } catch (err) {}
    },
    [publicService, form]
  );

  const handleSave = useCallback(
    async (payload: UserProfile) => {
      if (!profileService) return;
      if (!payload.nickname) return;

      try {
        setIsLoading(true);
        await profileService.updateUserProfile({
          ...payload,
          nickname: payload.nickname.toLowerCase(),
        });

        await handleFetchProfile();
        toast({
          title: "Profile updated",
          description: "Your profile has been updated successfully",
          type: ToastType.success,
        });
        modalProps.onClose();
      } catch (err) {
        toast({
          title: "Failed to update profile",
          description: "An error occurred while updating your profile",
          type: ToastType.error,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [handleFetchProfile, profileService]
  );

  useEffect(() => {
    if (!handleFetchProfile) return;
    handleFetchProfile().then();
  }, [handleFetchProfile]);

  return (
    <Modal isOpen={modalProps.isOpen} onClose={() => modalProps.onClose()}>
      <ModalOverlay />
      <ModalContent width={{ base: "400px", lg: "560px" }} height="auto">
        <ModalBody display="flex" flexDirection="column" gap="24px">
          <Flex alignItems="center" justifyContent="space-between">
            <Text size="2xl" color="primary.red">
              edit profile
            </Text>
            <Text
              size="lg"
              cursor="pointer"
              color="primary.yellow"
              _hover={{ color: "secondary.yellow" }}
              onClick={() => modalProps.onClose()}
            >{`[close]`}</Text>
          </Flex>
          <Flex flexDir="column" gap="8px">
            <Text size="xl" colorScheme="white">
              profile image
            </Text>
            <Flex alignItems="center" gap="12px">
              <Image
                src={
                  formValues?.avatar_url ||
                  user.avatar_url ||
                  "/images/avatar.png"
                }
                h="80px"
                w="80px"
                borderRadius="80px"
                border="1px solid"
                borderColor="white"
              />
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e)}
              />
              <Text
                size="lg"
                color="primary.yellow"
                cursor="pointer"
                onClick={() => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click();
                  }
                }}
              >{`[update]`}</Text>
              {/* <Text
                size="lg"
                color="primary.yellow"
                cursor="pointer"
              >{`[nuke]`}</Text> */}
            </Flex>
          </Flex>
          <form onSubmit={handleSubmit(handleSave)}>
            <Controller
              control={form.control}
              name="nickname"
              rules={{
                required: "Nickname is required",
                minLength: {
                  value: 3,
                  message: "Nickname must be at least 3 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Nickname must be at most 20 characters",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <Flex flexDir="column" gap="8px">
                  <Text size="xl" colorScheme="white">
                    username
                  </Text>
                  <Input
                    w="full"
                    value={value}
                    placeholder="enter your username"
                    onChange={(e) => onChange(e.target.value)}
                  />
                  {errors.nickname && (
                    <Text size="sm" color="primary.red" textAlign="right">
                      {errors.nickname.message}
                    </Text>
                  )}
                </Flex>
              )}
            />
            <Controller
              control={form.control}
              name="bio"
              render={({ field: { value, onChange } }) => (
                <Flex flexDir="column" gap="8px">
                  <Text size="xl" colorScheme="white">
                    bio
                  </Text>
                  <Input
                    w="full"
                    value={value}
                    placeholder="enter your bio"
                    onChange={(e) => onChange(e.target.value)}
                  />
                </Flex>
              )}
            />
            <Flex alignItems="center" justifyContent="center" mt="20px">
              <Button
                variant="empty"
                color="primary.red"
                onClick={() => modalProps.onClose()}
              >{`[cancel]`}</Button>
              <Button
                gap="10px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                isLoading={isLoading}
                type="submit"
              >
                Save
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
