import { Text, Button, Flex, Image, useDisclosure } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { useSupabase } from "@/hooks/useSupabase";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "../PopoverModal";
import { HeaderChainSelect } from "./HeaderChainSelect";
import { useWalletConnect } from "@/hooks/useWalletConnect";
import { EditProfileModal } from "../EditProfileModal";

export const HeaderWalletButton: FC = () => {
  const walletProfileDisclosure = useDisclosure();
  const editProfileModalDisclosure = useDisclosure();

  const { user, auth, logout } = useSupabase();
  const {
    address,
    solBalance,
    disconnectSolana,
    connectSolana,
    connected,
    handleLogin,
  } = useWalletConnect();

  useEffect(() => {
    if (connected && !auth?.access_token) {
      handleLogin();
      return;
    }

    // // console.log("address", address);
    if (
      address &&
      connected &&
      auth?.user.address &&
      auth?.user.address !== address
    ) {
      console.log("disconnecting", { address, auth });
      disconnectSolana();
      return;
    }
  }, [connected, auth, address]);

  const isLoading = false;

  return (
    <>
      <Flex alignItems="center" gap="8px" position="relative">
        {address && auth?.user?.id ? (
          <PopoverModal
            isOpen={walletProfileDisclosure.isOpen}
            onClose={walletProfileDisclosure.onClose}
            position={{ base: "unset" }}
            w={{ base: "unset", xl: "unset", lg: "unset" }}
            top={{ base: "unset", lg: "unset" }}
            bottom={{ base: "unset", lg: "unset" }}
            boxShadow="unset"
          >
            <PopoverModalTrigger>
              <Flex
                px="8px"
                py="4px"
                gap="10px"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="2px"
                border="1px solid"
                borderColor="primary.red"
                cursor="pointer"
                onClick={() => walletProfileDisclosure.onOpen()}
              >
                <Image
                  w="32px"
                  h="32px"
                  borderRadius="50%"
                  src={user?.avatar_url || "/images/avatar.png"}
                />
                <Text size={{ base: "lg", lg: "xl" }} color="white">
                  {`${address.substring(0, 5)}...${address.substring(address.length - 5, address.length)}`}
                </Text>
              </Flex>
            </PopoverModalTrigger>
            <PopoverModalContent
              p="0px"
              left="0px"
              top={{ base: "calc(100% + 5px)", lg: "calc(100% - 5px)" }}
              width="140px"
              overflow="hidden"
              display="flex"
              flexDir="column"
            >
              <Flex w="full" p="8px" _hover={{ bg: "stroke" }}>
                <Text color="secondary.yellow" size="xl">
                  {solBalance} SOL
                </Text>
              </Flex>
              <Flex
                w="full"
                p="8px"
                _hover={{ bg: "stroke" }}
                cursor="/icons/cursor-pointer.svg"
                onClick={() => editProfileModalDisclosure.onOpen()}
              >
                <Text size="xl" color="secondary.gray">
                  Edit profile
                </Text>
              </Flex>
              <Flex
                w="full"
                p="8px"
                _hover={{ bg: "stroke" }}
                onClick={async () => {
                  walletProfileDisclosure.onClose();
                  await disconnectSolana();
                }}
              >
                <Text color="primary.red" size="xl">
                  Disconnect
                </Text>
              </Flex>
              {editProfileModalDisclosure.isOpen && (
                <EditProfileModal {...editProfileModalDisclosure} />
              )}
            </PopoverModalContent>
          </PopoverModal>
        ) : (
          <Button
            loadingText="Signing in..."
            isLoading={isLoading}
            onClick={() => {
              connectSolana();
            }}
          >
            Connect wallet
          </Button>
        )}
        <HeaderChainSelect />
      </Flex>
    </>
  );
};
