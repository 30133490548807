import "reflect-metadata";

import { NextPage } from "next";
import { ReactNode } from "react";
import type { AppContext, AppProps } from "next/app";

import theme from "@/theme";
import { Inter } from "next/font/google";
import { ToastContainer } from "react-toastify";
import {
  ChakraProvider,
  cookieStorageManagerSSR,
  localStorageManager,
} from "@chakra-ui/react";

import { OnLoadModule } from "@/ui/LifeCycle/OnLoadModule";
import { RegularPageLayout } from "@/ui/Components/Layouts/RegularPageLayout";

import { store } from "@/redux/store";
import { Provider as StateProvider } from "react-redux";

import "@/styles/globals.css";
import "tailwindcss/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import "@solana/wallet-adapter-react-ui/styles.css";
import { Fonts } from "@/theme/fonts";
import { SolanaWeb3Provider } from "@/hooks/SolanaWeb3Provider";
import { SupabaseProvider } from "@/hooks/useSupabase";

const inter = Inter({ subsets: ["latin"] });

type Props = AppProps & { Component: NextPage };

export default function App({ Component, pageProps, ...props }: Props) {
  // const getHead = Component.getHead ?? (() => <></>);
  const getLayout =
    Component.getLayout ??
    ((page: ReactNode) => <RegularPageLayout>{page}</RegularPageLayout>);

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <StateProvider store={store}>
        <ChakraProvider
          theme={theme}
          colorModeManager={
            typeof (props as any)?.cookies === "string"
              ? cookieStorageManagerSSR((props as any)?.cookies)
              : localStorageManager
          }
        >
          <Fonts />
          <SolanaWeb3Provider>
            <SupabaseProvider>
              <OnLoadModule />
              {/* {getHead(pageProps)} */}
              {getLayout(<Component {...pageProps} />)}
              <ToastContainer autoClose={4000} />
            </SupabaseProvider>
          </SolanaWeb3Provider>
        </ChakraProvider>
      </StateProvider>
    </>
  );
}
