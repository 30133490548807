import { Box, Image } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { Header } from "@/ui/Components/Header/Header";
import { Center } from "@/ui/Components/Center";

import dynamic from "next/dynamic";
const Footer = dynamic(() => import("@/ui/Components/Footer"), { ssr: false });

export const RegularPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => (
  <Box minHeight="100vh" position="relative" overflow="hidden">
    <Box zIndex={2} position="relative" h="full">
      <Header />
      <Center minHeight="100vh">{children}</Center>
      <Footer />
    </Box>
    <Image
      src="/images/bombff.png"
      position="absolute"
      w="400px"
      bottom="0"
      zIndex={1}
      objectFit="cover"
      display={{ base: "none", lg: "block" }}
    />
  </Box>
);
