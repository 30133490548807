import { Global } from "@emotion/react";

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'CustomFont';
        src: url('/fonts/VT323-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
      }
      
      @font-face {
        font-family: 'DOS';
        src: url('/fonts/dos/ModernDOS8x16.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
      }

      body {
        cursor: url('/icons/cursor-default.svg'), default;
      }

      /* Pointer cursor */
      a, button, .clickable {
        cursor: url('/icons/cursor-pointer.svg'), pointer;
      }
    `}
  />
);
