import { SupabaseClient } from "@supabase/supabase-js";
import { SupabaseService } from "./supabase.service";
import { RefCodeEntity, ReferralAccount } from "@/types/supabase.entity";
import { toast, ToastType } from "@/ui/toast";

export type UserProfile = {
  user_id?: string;
  nickname?: string;
  avatar_url?: string;
  bio?: string;
};

export interface User {
  address: string;
  created_at: string;
  id: number;
  network: string;
  ref_from_user_id: string | null;
  refcode: string | null;
  user_id: string;
  volume_usd: number;
}

export class ProfileSupabaseService extends SupabaseService {
  constructor(
    protected readonly instance: SupabaseClient,
    private readonly userId: string
  ) {
    super(instance);
  }

  async getUser(userId: string) {
    return await this.instance.from("users").select("*").eq("user_id", userId);
  }

  // Auth DB
  async getProfile() {
    return await this.instance
      .from("users")
      .select("*")
      .eq("user_id", this.userId);
  }

  async getProfileByUserId(userId: string) {
    return await this.instance
      .from("user_profile")
      .select("*")
      .eq("user_id", userId);
  }

  async getUserProfile() {
    const userProfile = await this.instance
      .from("user_profile")
      .select("*")
      .eq("user_id", this.userId);

    console.log("this user id", this.userId);
    if (userProfile.error) {
      console.warn("Error fetching user profile", userProfile.error);
      return {} as UserProfile;
    }

    return (userProfile.data?.[0] ?? {}) as UserProfile;
  }

  async updateRefCode(refCode: string) {
    return await this.instance
      .from("refcode")
      .insert([{ code: refCode, user_id: this.userId }])
      .select();
  }

  async getNickname() {
    const response = await this.instance
      .from("user_profile")
      .select("*")
      .eq("user_id", this.userId);

    if (response.error) {
      console.warn("Error fetching nickname", response.error);
      return "";
    }

    if (!response.data.length) return "";

    return response.data[0].nickname;
  }

  async updateNickname(nickname: string) {
    const existingNickname = await this.getNickname();
    if (existingNickname) {
      return await this.instance
        .from("user_profile")
        .update({ nickname })
        .eq("user_id", this.userId);
    }

    return await this.instance
      .from("user_profile")
      .insert({ nickname, user_id: this.userId })
      .select();
  }

  async updateUserProfile(payload: UserProfile) {
    const existingProfile = await this.getUserProfile();
    let response;
    if (existingProfile?.user_id) {
      response = await this.instance
        .from("user_profile")
        .update({ ...payload, user_id: this.userId })
        .eq("user_id", this.userId);
    } else {
      response = await this.instance
        .from("user_profile")
        .insert({ ...payload, user_id: this.userId })
        .select();
    }

    if (response.error) {
      console.warn("Error updating user profile", response.error);
      throw new Error("Failed to update user profile");
    }

    return true;
  }

  async getRefCode() {
    const response = await this.instance
      .from("refcode")
      .select("*")
      .eq("user_id", this.userId);

    if (response.error) {
      console.warn("Error fetching ref code", response.error);
      return "";
    }

    const refs = response.data as RefCodeEntity[];
    if (!refs.length) {
      // auto-generate ref code
      const refCode = Math.random().toString(36).substring(2, 8);
      const response = await this.updateRefCode(refCode);
      if (response.error) {
        console.warn("Error updating ref code", response.error);
        return "";
      }

      return response.data[0] as RefCodeEntity;
    }

    // return the latest ref code by created_at
    const latestRef = refs.sort((a, b) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    })[0];

    return latestRef;
  }

  async getUserRank(userId?: string) {
    console.log(`fetching user rank for ${this.userId}`);
    const response = await this.instance
      .from("view_ref_rank")
      .select("*")
      .eq("user_id", this.userId || userId);

    if (response.error) {
      console.warn("Error fetching user rank", response.error);
      throw new Error("Error fetching user rank");
    }

    return response.data[0];
  }

  async getTopRefs({
    query,
    limit,
    offset,
  }: {
    query?: string;
    limit?: number;
    offset?: number;
  }) {
    let response;

    if (!query) {
      response = await this.instance
        .from("view_ref_rank")
        .select("*")
        .range(offset, offset + limit - 1);
    } else {
      response = await this.instance
        .from("view_ref_rank")
        .select("*")
        .like("nickname", `%${query}%`)
        .order("rank", { ascending: true });
    }

    if (response.error) {
      console.warn("Error fetching top refs", response.error);
      return [];
    }

    return response.data ?? [];
  }
}
