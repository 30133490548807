import { FC, useRef } from "react";
import useIsMobile from "@/hooks/useIsMobile";
import { isGroupItem, useNav } from "@/hooks/useNavs";
import {
  Box,
  Flex,
  Menu,
  useDisclosure,
  useBreakpoint,
  useColorModeValue,
  Link,
  Image,
} from "@chakra-ui/react";

import { NavLink, NavLinkDropDown } from "../NavLink";
import HeaderProfileMenu from "./HeaderProfileMenu";
import { route } from "nextjs-routes";

export const Header: FC = () => {
  const isMobile = useIsMobile();
  const breakpoint = useBreakpoint();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const containerRef = useRef(null);
  const { navItems } = useNav();

  const renderNavigationContent = (
    <>
      {navItems.map((item, index) => {
        return (
          <Box
            _first={{ mt: { base: "2rem", lg: "unset" } }}
            _last={{ mb: { base: "2rem", lg: "unset" } }}
            key={index}
          >
            {!isGroupItem(item) && (
              <Box key={`menu-item-${Math.random().toString()}`}>
                <Menu>
                  <NavLink
                    w="100%"
                    item={item}
                    color={
                      (item as any)?.isActive
                        ? useColorModeValue("primary.red", "primary.red")
                        : useColorModeValue("secondary.gray", "secondary.gray")
                    }
                    _hover={{
                      color: !item.disabled
                        ? useColorModeValue("primary.red", "primary.red")
                        : "secondary.gray",
                    }}
                    cursor={!item.disabled ? "pointer" : "not-allowed"}
                    isActive={(item as any)?.isActive}
                    onClick={() => {
                      if (isMobile) {
                        onToggle();
                      }
                    }}
                  />
                </Menu>
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );

  return (
    <Box
      ref={containerRef}
      zIndex={999}
      as="header"
      bgColor={useColorModeValue("black", "black")}
      transitionProperty="box-shadow"
      transitionDuration="slow"
      width="full"
      id="header"
      position="relative"
      alignItems="stretch"
      boxShadow={useColorModeValue(
        "0px 2px 6px 0px rgba(13, 26, 38, 0.15)",
        "lg"
      )}
      borderBottom="1px solid"
      borderColor="stroke"
    >
      <Flex
        alignItems="center"
        gap={4}
        justifyContent="space-between"
        py="12px"
        mx="auto"
        px={{ base: "16px", lg: "24px", xl: "40px" }}
        w={{ base: "full", xl: "full" }}
      >
        <Flex alignItems="center" gap="60px">
          <Link href={route({ pathname: "/" })}>
            <Image
              h="40px"
              w={{ base: "200px" }}
              alt="unpump.fun"
              src="/images/logo.png"
              display={{ base: "none", lg: "block" }}
            />
            <Image
              h="40px"
              w="40px"
              alt="unpump.fun"
              src="/images/logo-mobile.png"
              display={{ base: "block", lg: "none" }}
            />
          </Link>
          <Flex display={{ base: "none", lg: "flex" }} gap="32px">
            {breakpoint === "lg" ? (
              <NavLinkDropDown items={navItems} />
            ) : (
              renderNavigationContent
            )}
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          float="right"
          gap={{ base: "12px", lg: 5, xl: 8 }}
        >
          <Flex
            order={1}
            alignItems="center"
            gap={{ base: 2, lg: 5, xl: 8 }}
            float="right"
          >
            <Flex
              left={0}
              top="61px"
              zIndex={1001}
              height={{
                base: isOpen ? `auto` : "0px",
                lg: "unset",
              }}
              gap={{ base: "12px", lg: 5, xl: 8 }}
              transition="height 0.3s ease-in-out !important"
              paddingX={{ base: 4, lg: "unset" }}
              onClick={(e) => e.stopPropagation()}
              width={{ base: "full", lg: "unset" }}
              boxShadow={{ base: "md", lg: "none" }}
              overflowY={{ base: "auto", lg: "unset" }}
              position={{ base: "absolute", lg: "static" }}
              flexDirection={{ base: "column", lg: "row" }}
              alignItems={{ base: "stretch", lg: "center" }}
              sx={{ transform: { lg: "translate(0, 0) !important" } }}
              justifyContent={{ base: "space-between", lg: "flex-end" }}
              maxHeight={{ lg: "2.25rem !important" }}
              display={{ base: "flex", lg: "none" }}
              bgColor={useColorModeValue("black", "black")}
            >
              {breakpoint === "md" || breakpoint === "sm" ? (
                <Flex display="flex" flexDirection="column">
                  {renderNavigationContent}
                </Flex>
              ) : (
                renderNavigationContent
              )}
            </Flex>
          </Flex>
          <Box
            position="relative"
            id="zzz"
            order={3}
            display="flex"
            alignItems="center"
            gap="12px"
          >
            <HeaderProfileMenu
              isOpenDropdown={isOpen}
              onClick={onClose}
              onToggleDropdown={() => onToggle()}
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
