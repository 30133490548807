import {
  NavGroupItem,
  NavItem,
  NavItemInternal,
} from "@/types/navigation-types";
import { useRouter } from "next/router";
import { useMemo } from "react";

export function isGroupItem(
  item: NavItem | NavGroupItem
): item is NavGroupItem {
  return "subItems" in item;
}

export function isInternalItem(item: NavItem): item is NavItemInternal {
  return "nextRoute" in item;
}

export const useNav = () => {
  const router = useRouter();

  return useMemo(() => {
    const navItems = (
      [
        {
          text: "Purge",
          nextRoute: { pathname: "/" },
          isActive: ["/"],
        },
        {
          text: "Purge Collective",
          nextRoute: { pathname: "/purge-collective" },
          disabled: true,
        },
        {
          text: "Survival Showdown",
          nextRoute: { pathname: "/survival-showdown" },
          disabled: true,
        },
        {
          text: "Leaderboard",
          nextRoute: { pathname: "/leaderboard" },
          disabled: true,
        },
        {
          text: "Stats",
          nextRoute: { pathname: "/stats" },
          disabled: true,
        },
      ] as NavItem[]
    ).map(({ ...data }) => ({
      ...data,
    }));

    return {
      navItems: navItems as NavItem[],
    };
  }, [router]);
};
