import { FC, useEffect } from "react";

export const OnLoadModule: FC = () => {
  useEffect(() => {
    document.querySelectorAll("*").forEach((el) => {
      const computedCursor = window.getComputedStyle(el).cursor;
      if (computedCursor === "pointer") {
        (el as any).style.cursor = "url('/icons/cursor-pointer.svg'), pointer";
      }
    });
  }, []);

  return <></>;
};
