import type { SystemStyleInterpolation } from "@chakra-ui/styled-system";
import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";

const variantPrimary = defineStyle((props) => {
  const { colorScheme } = props;

  const renderColor = () => {
    if (!colorScheme) {
      return mode("white", "white")(props);
    }

    switch (colorScheme) {
      case "primary":
        return mode("neutral.on.surface.1", "neutral.element.primary")(props);
      case "secondary":
        return mode(
          "neutral.element.secondary_light_theme",
          "neutral.element.secondary"
        )(props);
      case "tertiary.primary":
        return mode(
          "neutral.element.tertiary_light_theme",
          "neutral.element.tertiary"
        )(props);
      case "tertiary":
        return mode("#7A7A7A", "neutral.element.tertiary2")(props);
      case "quaternary":
        return mode(
          "neutral.element.quaternary",
          "neutral.element.quaternary"
        )(props);
      case "quinary":
        return mode(
          "neutral.element.quinary",
          "neutral.element.quinary"
        )(props);
      case "senary":
        return mode("neutral.element.senary", "neutral.element.senary")(props);
      case "septenary":
        return mode(
          "neutral.element.septenary",
          "neutral.element.septenary"
        )(props);
      case "octonary":
        return mode(
          "neutral.element.octonary",
          "neutral.element.octonary"
        )(props);
      case "nonary":
        return mode("neutral.element.nonary", "neutral.element.nonary")(props);
      case "denary":
        return mode("neutral.element.denary", "neutral.element.denary")(props);
      case "decorative7":
        return mode("decorative.07", "decorative.07")(props);
      case "white":
        return mode("white", "white")(props);
      default:
        return;
    }
  };

  return {
    color: renderColor(),
  };
});

const sizes = {
  xsm: defineStyle({
    fontSize: "12px",
    fontWeight: 500,
  }),
  sm: defineStyle({
    fontSize: "14px",
    fontWeight: 500,
  }),
  md: defineStyle({
    fontSize: "16px",
    fontWeight: 500,
  }),
  lg: defineStyle({
    fontSize: "18px",
    fontWeight: 500,
  }),
  xl: defineStyle({
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
  }),
  "2xl": defineStyle({
    fontSize: "24px",
    fontWeight: 500,
  }),
  "3xl": defineStyle({
    fontSize: "32px",
    fontWeight: 700,
  }),
  "4xl": defineStyle({
    fontSize: "36px",
    fontWeight: 500,
  }),
  "4xxl": defineStyle({
    fontSize: "40px",
    fontWeight: 700,
  }),
  "5xl": defineStyle({
    fontSize: "52px",
    lineHeight: "52px",
    fontWeight: 700,
  }),
  "6xl": defineStyle({
    fontWeight: 700,
    fontSize: "64px",
  }),
  "7xl": defineStyle({
    fontSize: "80px",
    fontWeight: 700,
  }),
  "7xxl": defineStyle({
    fontSize: "81px",
    fontWeight: 700,
  }),
};

const variants: Record<string, SystemStyleInterpolation> = {
  primary: variantPrimary,
  link: defineStyle({
    color: "white",
    cursor: "/icons/cursor-pointer.svg",
    _hover: {
      color: "primary.red",
    },
  }),
};

const defaultProps = {
  variant: "primary",
  alignItems: "center",
};

const Text = defineStyleConfig({
  variants,
  sizes,
  defaultProps: {
    ...defaultProps,
    size: "md",
  },
});

export default Text;
