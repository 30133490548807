import { FlexProps } from "@chakra-ui/react";
import { memo } from "react";
import HeaderHamburgerButton from "./HeaderHamburgerButton";
import { HeaderWalletButton } from "./HeaderWalletButton";

type Props = {
  isOpenDropdown: boolean;
  onToggleDropdown: () => void;
  onClick?: FlexProps["onClick"];
};

const HeaderProfileMenu = ({ isOpenDropdown, onToggleDropdown }: Props) => {
  return (
    <>
      <HeaderWalletButton />
      <HeaderHamburgerButton
        display={{ lg: "none" }}
        onClick={() => onToggleDropdown()}
        isOpen={isOpenDropdown}
      ></HeaderHamburgerButton>
    </>
  );
};

export default memo(HeaderProfileMenu);
