import { StorageProvider } from "@/lib/providers/storage.provider";
import {
  ChatSupabaseService,
  CommentDtoPayload,
} from "@/lib/services/chat.supbase.service";
import {
  ProfileSupabaseService,
  User,
  UserProfile,
} from "@/lib/services/profile.supabase.service";
import { UtilitySupabaseService } from "@/lib/services/utility.supabase.service";
import { useWallet } from "@solana/wallet-adapter-react";
import { createClient } from "@supabase/supabase-js";
import * as bs58 from "bs58";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";

export const ACCESS_TOKEN_STORE_KEY = "UNPUMP_FUN_ACCESS_TOKEN";
export const REFRESH_TOKEN_STORE_KEY = "UNPUMP_FUN_REFRESH_TOKEN";
export const ACCESS_TOKEN_USER_ID = "UNPUMP_FUN_USER_ID";
export const REF_CODE_STORE_KEY = "UNPUMP_FUN_REF_CODE";
const NEXT_PUBLIC_SUPABASE_URL = "https://hmtjgsnttgduqdibsyob.supabase.co";
const NEXT_PUBLIC_SUPABASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhtdGpnc250dGdkdXFkaWJzeW9iIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzMxNjI1NDcsImV4cCI6MjA0ODczODU0N30.h_0PjUImhVykc5n73lmQqmdjMDbR4aFTBaW2JA78tD4";

type AuthResponse = {
  access_token: string;
  token_type: string;
  refresh_token: string;
  user: {
    id: string;
    address: string | undefined;
  };
};

const getInstance = (accessToken: string) => {
  return createClient(NEXT_PUBLIC_SUPABASE_URL, NEXT_PUBLIC_SUPABASE_ANON_KEY, {
    global: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });
};

type PublicService = {
  profile: ProfileSupabaseService;
  utility: UtilitySupabaseService;
  chat: ChatSupabaseService;
};

type AuthService = {
  profile: ProfileSupabaseService;
  chat: ChatSupabaseService;
};

type ContextProps = {
  auth: AuthResponse;
  publicService: PublicService;
  authService: AuthService;

  // Profile
  logout(): void;
  loginSolana(): Promise<void>;
  handleFetchProfile(): Promise<void>;

  // Chat
  postComment(topic: string, payload: CommentDtoPayload): Promise<void>;
} & { user: UserProfile };

const AuthContext = createContext<ContextProps | undefined>(undefined);

export const SupabaseProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const supabase = createClient(
    NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_SUPABASE_ANON_KEY
  );

  const { publicKey, signMessage } = useWallet();

  const [auth, setAuth] = useState<AuthResponse>();
  const [userId, setUserId] = useState<string>(
    new StorageProvider().getItem(ACCESS_TOKEN_USER_ID) || ""
  );
  const [accessToken, setAccessToken] = useState(
    new StorageProvider().getItem(ACCESS_TOKEN_STORE_KEY) || ""
  );

  const [publicService, setPublicService] = useState<PublicService>();
  const [authService, setAuthService] = useState<AuthService>();

  const userProfileForm = useForm<UserProfile>({
    defaultValues: { nickname: "", user_id: "", avatar_url: "", bio: "" },
  });

  const userProfile = useWatch({ control: userProfileForm.control });

  const handleRefreshToken = useCallback(async () => {
    const refreshToken = new StorageProvider().getItem(REFRESH_TOKEN_STORE_KEY);
    return await supabase.auth.refreshSession({ refresh_token: refreshToken });
  }, []);

  const handleFetchAuthProfile = useCallback(async () => {
    const fnc = async (token: string, userId: string) => {
      if (!token) throw new Error("No token found");

      console.log('Registered services as user_id', userId);
      const profileService = new ProfileSupabaseService(
        getInstance(token),
        userId
      );

      const authProfile = await profileService.getProfile();
      if (authProfile.error) {
        throw new Error(authProfile.error.message);
      }

      const chatService = new ChatSupabaseService(getInstance(token), userId);
      setAuthService({ profile: profileService, chat: chatService });

      const r = await profileService.getUser(userId);
      const user = r.data?.[0] as User | undefined;
      setAuth({
        access_token: token,
        token_type: "Bearer",
        refresh_token: new StorageProvider().getItem(REFRESH_TOKEN_STORE_KEY),
        user: {
          id: userId,
          address: user?.address,
        },
      });

      userProfileForm.setValue("user_id", userId);
      return authProfile;
    };

    try {
      const distributedToken =
        accessToken || new StorageProvider().getItem(ACCESS_TOKEN_STORE_KEY);

      const _userId =
        new StorageProvider().getItem(ACCESS_TOKEN_USER_ID) || userId;

      console.log({_userId});

      return await fnc(distributedToken, _userId);
    } catch {
      const refreshTokenData = await handleRefreshToken();
      if (refreshTokenData.error) {
        return null;
      }

      new StorageProvider().setItem(
        REFRESH_TOKEN_STORE_KEY,
        refreshTokenData.data.session.access_token
      );

      new StorageProvider().setItem(
        REFRESH_TOKEN_STORE_KEY,
        refreshTokenData.data.session.refresh_token
      );

      console.log({'refreshTokenData.data.user.id': refreshTokenData.data.user.id});
      try {
        return await fnc(
          refreshTokenData.data.session.access_token,
          refreshTokenData.data.user.id
        );
      } catch {
        return null;
      }
    }
  }, [accessToken, auth]);

  const handleFetchProfile = useCallback(async () => {
    if (!authService || !auth.user.id) return;
    const userProfile = await authService.profile.getUserProfile();
    for (const key in userProfile) {
      userProfileForm.setValue(key as any, (userProfile as any)[key]);
    }
  }, [authService, auth]);

  const loginSolana = useCallback(async () => {
    if (!publicKey) return;

    var nonce = Date.now();
    const message = Buffer.from("unpump.fun request_id: " + nonce, "utf-8");
    const signature = await signMessage(message);

    const response = (await new Promise((resolve, reject) => {
      fetch("/api/login-sol", {
        headers: {
          contentType: "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          nonce,
          signature: bs58.default.encode(signature),
          address: publicKey.toBase58().toString(),
          refcode: new StorageProvider().getItem(REF_CODE_STORE_KEY) || "",
        }),
      })
        .then((res) => res.json())
        .then((data) => resolve(data))
        .catch((error) => {
          console.error("error", error);
          reject(error);
        });
    })) as {
      error: string;
      data: {
        data: AuthResponse;
      };
    };

    if (response.error) {
      throw new Error(response.error);
    }

    new StorageProvider().setItem(
      ACCESS_TOKEN_STORE_KEY,
      response.data.data.access_token
    );
    new StorageProvider().setItem(
      REFRESH_TOKEN_STORE_KEY,
      response.data.data.refresh_token
    );
    new StorageProvider().setItem(
      ACCESS_TOKEN_USER_ID,
      response.data.data.user.id
    );
    setAccessToken(response.data.data.access_token);
    setUserId(response.data.data.user.id);
    setAuth(response.data.data);
    const chatService = new ChatSupabaseService(
      getInstance(response.data.data.access_token),
      response.data.data.user.id
    );
    const profileService = new ProfileSupabaseService(
      getInstance(response.data.data.access_token),
      response.data.data.user.id
    );

    setAuthService({
      profile: profileService,
      chat: chatService,
    });
    userProfileForm.setValue("user_id", userId);
  }, [publicKey, signMessage]);

  const logout = useCallback(() => {
    console.log("logout");
    new StorageProvider().removeItem(REFRESH_TOKEN_STORE_KEY);
    new StorageProvider().removeItem(ACCESS_TOKEN_STORE_KEY);
    new StorageProvider().removeItem(ACCESS_TOKEN_USER_ID);
    setAccessToken("");
    setUserId("");
    setAuth(undefined);
    setAuthService(undefined);
    userProfileForm.reset();
  }, []);

  const postComment = useCallback(
    async (topic: string, payload: CommentDtoPayload) => {
      if (!authService) return;
      await authService.chat.postComment(topic, payload);
    },
    [authService]
  );

  useEffect(() => {
    if (!authService || !auth) return;
    handleFetchProfile();
  }, [authService, auth]);

  useEffect(() => {
    if (!publicKey) return;
    handleFetchAuthProfile();
  }, [publicKey]);

  useEffect(() => {
    setPublicService({
      profile: new ProfileSupabaseService(supabase, ""),
      utility: new UtilitySupabaseService(supabase),
      chat: new ChatSupabaseService(supabase, ""),
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        logout,
        postComment,
        loginSolana,
        handleFetchProfile,
        user: { ...userProfile },
        authService: authService || { profile: null, chat: null },
        publicService: publicService || {
          profile: null,
          utility: null,
          chat: null,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useSupabase = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useSupabase must be used within a SupabaseProvider");
  }
  return context;
};
