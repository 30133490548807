import {
  Box,
  Flex,
  Text,
  TextProps,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC } from "react";

import type { NavItem } from "@/types/navigation-types";
import { useRouter } from "next/router";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "./PopoverModal";

type Props = {
  item: NavItem;
  color?: string;
  px?: string | number;
  isActive?: boolean;
  onClick?: () => void;
} & Partial<TextProps>;

export const NavLink = ({
  item,
  color,
  isActive,
  onClick,
  ...props
}: Props) => {
  const router = useRouter();
  const content = (
    <Text
      borderRadius={{ base: "md", lg: "unset" }}
      whiteSpace="nowrap"
      onClick={() => {
        if (item.disabled) return;
        if ((item as any)?.nextRoute) {
          router.push((item as any).nextRoute?.pathname);
        }

        onClick && onClick();
      }}
      width="full"
      height="full"
      role="group"
      color={color || "secondary.gray"}
      _hover={{
        color: "neutral.2",
      }}
      fontWeight={400}
      fontSize="20px"
      gap={2}
      borderBottom={{ base: "none", lg: isActive ? "1px solid" : "none" }}
      borderColor="primary.red"
      {...props}
      display="flex"
      justifyContent="flex-start"
    >
      {item.text}
    </Text>
  );

  return content;
};

export const NavLinkDropDown: FC<{ items: NavItem[] }> = ({ items }) => {
  const modalDisclosure = useDisclosure();

  return (
    <Box position="relative">
      <PopoverModal
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        order={3}
        position={{ base: "unset" }}
        w={{ base: "unset", xl: "unset", lg: "unset" }}
        top={{ base: "unset", lg: "unset" }}
        bottom={{ base: "unset", lg: "unset" }}
        boxShadow="unset"
      >
        <PopoverModalTrigger>
          <Flex
            gap="4px"
            alignItems="center"
            cursor="/icons/cursor-pointer.svg"
            onClick={() => modalDisclosure.onToggle()}
          >
            <Text fontSize="20px" color="primary.red">
              {items?.[0].text}
            </Text>
            <Image
              src="/icons/dropdown.svg"
              w="20px"
              h="20px"
              transition="transform 0.3s linear"
              transform={`rotate(${modalDisclosure.isOpen ? 180 : 0}deg)`}
            />
          </Flex>
        </PopoverModalTrigger>
        <PopoverModalContent
          px="8px"
          lef="0px"
          top="20px"
          width="170px"
          overflow="hidden"
          display="flex"
          flexDir="column"
          gap="5px"
        >
          {items.map((item) => (
            <NavLink
              key={`${Math.random()}-${item.text}`}
              item={item}
              color={(item as any)?.isActive ? "primary.red" : "secondary.gray"}
              _hover={{
                color: !item.disabled ? "primary.red" : "secondary.gray",
              }}
              cursor={!item.disabled ? "pointer" : "not-allowed"}
              isActive={(item as any)?.isActive}
              borderBottom="none"
              onClick={() => {
                modalDisclosure.onClose();
              }}
            />
          ))}
        </PopoverModalContent>
      </PopoverModal>
    </Box>
  );
};
