import { clusterApiUrl, Connection } from "@solana/web3.js";

export const useSolanaNetwork = () => {
  const network = clusterApiUrl("mainnet-beta");
  const connection = new Connection(
    "https://skilled-young-sun.solana-mainnet.quiknode.pro/432823b5a88d8df74894b79426169da1ecc63f8b"
  );

  return { network, connection };
};
