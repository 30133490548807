import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { Textarea as TextAreaComponent } from "@chakra-ui/react";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const variantForm = definePartsStyle(() => {
  return {
    field: {
      color: "white",
      border: "1px solid",
      borderColor: "white",
      bg: "black",
      borderRadius: "2px",
      _focus: {
        borderColor: "primary.red",
      },
    },
  };
});

const TextArea = defineMultiStyleConfig({
  variants: {
    form: variantForm,
  },
  defaultProps: {
    variant: "form",
  },
});

TextAreaComponent.defaultProps = {
  ...TextAreaComponent.defaultProps,
  placeholder: " ",
};

export default TextArea;
