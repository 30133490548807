import { Text, Flex, Image, useDisclosure, Divider } from "@chakra-ui/react";
import { type FC } from "react";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "../PopoverModal";

export const HeaderChainSelect: FC = () => {
  const chainMenuDisclosure = useDisclosure();

  return (
    <PopoverModal
      isOpen={chainMenuDisclosure.isOpen}
      onClose={chainMenuDisclosure.onClose}
      order={3}
      position={{ base: "unset" }}
      w={{ base: "unset", xl: "unset", lg: "unset" }}
      top={{ base: "unset", lg: "unset" }}
      bottom={{ base: "unset", lg: "unset" }}
      boxShadow="unset"
    >
      <PopoverModalTrigger>
        <Flex
          p="4px"
          gap="4px"
          borderRadius="2px"
          border="1px solid"
          borderColor="stroke"
          alignItems="center"
          cursor="/icons/cursor-pointer.svg"
          onClick={() => chainMenuDisclosure.onToggle()}
          h="40px"
        >
          <Image
            src="/images/solana.png"
            w="32px"
            h="32px"
            borderRadius="32px"
          />
          <Image
            src="/icons/dropdown.svg"
            w="20px"
            h="20px"
            transition="transform 0.3s linear"
            transform={`rotate(${chainMenuDisclosure.isOpen ? 180 : 0}deg)`}
          />
        </Flex>
      </PopoverModalTrigger>
      <PopoverModalContent
        p="0px"
        right="0px"
        top={{ base: "calc(100% + 5px)", lg: "calc(100% - 5px)" }}
        width="130px"
        overflow="hidden"
        display="flex"
        flexDir="column"
        gap="5px"
      >
        <Flex
          w="full"
          px="16px"
          py="8px"
          alignItems="center"
          justifyContent="space-between"
          _hover={{ bg: "stroke" }}
          cursor="pointer"
        >
          <Image
            src="/images/solana.png"
            w="24px"
            h="24px"
            borderRadius="24px"
          />
          <Text size="xl" color="secondary.gray">Solana</Text>
        </Flex>
        {/* <Divider w="80%" mx="auto" color="stroke" />
        <Flex
          w="full"
          px="16px"
          py="8px"
          alignItems="center"
          justifyContent="space-between"
          _hover={{ bg: "stroke" }}
          cursor="pointer"
        >
          <Image w="24px" h="24px" src="/icons/base.svg" borderRadius="36px" />
          <Text size="xl">Base</Text>
        </Flex> */}
      </PopoverModalContent>
    </PopoverModal>
  );
};
