import { useWallet as useSolanaWallet } from "@solana/wallet-adapter-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSolanaNetwork } from "./useSolanaNetwork";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useSupabase } from "./useSupabase";
import { useDebounce } from "@uidotdev/usehooks";

const useSolana = () => {
  const {
    publicKey,
    disconnect: disconnectSolana,
    connected,
  } = useSolanaWallet();
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { setVisible } = useWalletModal();
  const { connection } = useSolanaNetwork();
  const fetchBalance = useCallback(async () => {
    if (!publicKey) return;

    try {
      setIsLoading(true);
      const lamports = await connection.getBalance(publicKey);
      const solBalance = lamports / 10 ** 9;
      setBalance(solBalance);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
      setBalance(0);
    } finally {
      setIsLoading(false);
    }
  }, [connection, publicKey]);

  useEffect(() => {
    fetchBalance();
  }, [publicKey]);

  return {
    connected,
    balance,
    publicKey,
    isLoading,
    disconnectSolana,
    connectSolana: async () => {
      try {
        setVisible(true);
      } catch (err) {
        console.error("Failed to connect to wallet:", err);
      }
    },
    fetchBalance,
  };
};

export const useWalletConnect = () => {
  const {
    publicKey: _publicKey,
    balance: solBalance,
    disconnectSolana,
    connectSolana,
    connected,
  } = useSolana();

  const publicKey = useDebounce(_publicKey, 2000);

  const { logout } = useSupabase();
  const { loginSolana } = useSupabase();

  const balance = AppNumber.from(solBalance);
  const previousPublicKey = useRef(null);

  const handleDisconnectSolana = useCallback(async () => {
    await disconnectSolana();
    logout();
    previousPublicKey.current = null;
  }, [logout, disconnectSolana]);

  const handleLogin = useCallback(async () => {
    if (!publicKey) return;
    try {
      await loginSolana();
    } catch (error) {
      await handleDisconnectSolana();
    }
  }, [publicKey, loginSolana]);

  return {
    connected,
    handleLogin,
    publicKey,
    address: publicKey?.toBase58()?.toString(),
    solBalance: solBalance,
    balance,
    connectSolana,
    disconnectSolana: handleDisconnectSolana,
  };
};
