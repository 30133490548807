import { SupabaseService } from "./supabase.service";
import { toast, ToastType } from "@/ui/toast";

export class UtilitySupabaseService extends SupabaseService {
  async uploadImage(image: any) {
    if (!image) {
      alert("Please select an image first.");
      return "";
    }

    const fileName = `${Date.now()}_${image.name}`;
    const { error } = await this.instance.storage
      .from("user_files") // Replace with your storage bucket name
      .upload(`images/${fileName}`, image);

    if (error) {
      toast({
        title: "Error uploading image",
        description: "An error occurred while uploading the image",
        type: ToastType.error,
      });

      throw new Error("Failed to upload image");
    }

    // Generate public URL
    const {
      data: { publicUrl },
    } = this.instance.storage
      .from("user_files")
      .getPublicUrl(`images/${fileName}`);

    toast({
      title: "Image uploaded",
      description: "The image has been successfully uploaded",
      type: ToastType.success,
    });
    return publicUrl;
  }
}
